import addImg from "../../assets/addImg.svg";
import addImgWhite from "../../assets/addImgWhite.svg";
import uploadImg from "../../assets/uploadImg.svg";
import recentImg from "../../assets/recentImage.svg";
import searchImg from "../../assets/searchImg.svg";
import uploadImgWhite from "../../assets/uploadImgWhite.svg";
import recentImgWhite from "../../assets/recentImgWhite.svg";
import searchImgWhite from "../../assets/searchImgWhite.svg";
import deleteSlide from "../../assets/deleteSlide.svg";
import imgSetting from "../../assets/imgSetting.svg";
import imgSettingWhite from "../../assets/imgSettingWhite.svg";
import addSlide from "../../assets/addSlide.svg";
import adjust from "../../assets/adjust.svg";
import adjustWhite from "../../assets/adjustwhite.svg";
import duplicateSlideImg from "../../assets/duplicateSlideImg.svg";
import disabledDuplicate from "../../assets/disabledDuplicate.svg";
import disabledAdd from "../../assets/disabledAdd.svg";
import disabledDelete from "../../assets/disabledDelete.svg";
import swipeArr from "../../assets/swipeArr.svg";
import bookmarkImg from "../../assets/bookmark.svg";
import rightArr from "../../assets/rightArr.svg";
import circle from "../../assets/circle.svg";
import thunder from "../../assets/thunder.svg";
import leftAlign from "../../assets/leftAlign.svg";
import rightAlign from "../../assets/rightAlign.svg";
import centerAlign from "../../assets/centerAlign.svg";
import leftAlignWhite from "../../assets/leftAlignWhite.svg";
import rightAlignWhite from "../../assets/rightAlignWhite.svg";
import centerAlignWhite from "../../assets/centerAlignWhite.svg";
import maximize from "../../assets/maximize.svg";
import minimize from "../../assets/minimize.svg";
import maximizeWhite from "../../assets/maximizeWhite.svg";
import minimizeWhite from "../../assets/minimizeWhite.svg";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DomToImage from "dom-to-image";
import { userProfileData } from "../../store/features/analytics/analyticsAction";
import Modal from "react-bootstrap/esm/Modal";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import {
  adjustDesc,
  adjustImage,
  adjustSubtitle,
  adjustTitle,
  addSlideData,
  deleteSlideData,
  backgroundImageSet,
  backgroundImageOpacitySet,
  backgroundImageSizeSet,
  backgroundImagePositionSet,
  backgroundImageRemove,
  imageSet,
  duplicateSlideData,
  imageSizeSet,
  imagePositionSet,
  backgroundImageAllSet,
  revertbackgroundImageSet,
  backgroundAllSet,
  changeSubtitle,
  changeTitle,
  changeDesc,
} from "../../store/features/carousel/action/adjustAction";
import { Switch } from "../../components/ui/switch";
import {
  imageUploadAction,
  clearImages,
  deleteRecentImage,
  pexelImageData,
  pixabayImageData,
  unsplashImageData,
} from "../../store/features/carousel/action/imageUploadAction";
// import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isLoading } from "../../store/features/common/commonSlice";
import "./carouselPage.css"


const PatternContainer = ({ pattern, bgOpacity, color = 'rgb(128, 128, 128)' }) => {
  const baseOpacity = Math.min(bgOpacity * 1.5, 1);

  const svgPatterns = {
    grid: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="grid" width="60" height="60" patternUnits="userSpaceOnUse">
            <path d="M 60 0 L 0 0 0 60" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="0.8"/>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
      </svg>
    ),
    dots: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="dots" width="25" height="25" patternUnits="userSpaceOnUse">
            <circle cx="12.5" cy="12.5" r="2" fill={`${color}`} fillOpacity={baseOpacity} />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#dots)" />
      </svg>
    ),
    stripped: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="stripes" width="40" height="40" patternUnits="userSpaceOnUse">
            <rect width="1" height="40" x="0" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="3" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="6" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="20" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="23" fill={`${color}`} fillOpacity={baseOpacity} />
            <rect width="1" height="40" x="26" fill={`${color}`} fillOpacity={baseOpacity} />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#stripes)" />
      </svg>
    ),
    diagonals: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="diagonals" width="20" height="20" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
            <line x1="0" y1="0" x2="0" y2="20" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="3"/>
            <line x1="10" y1="0" x2="10" y2="20" stroke={`${color}`} strokeOpacity={baseOpacity * 0.5} strokeWidth="1.5"/>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#diagonals)" />
      </svg>
    ),
    cage: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="cage" width="100" height="100" patternUnits="userSpaceOnUse">
            <path d="M25 50 A 25 25 0 0 1 50 25 A 25 25 0 0 1 75 50 A 25 25 0 0 1 50 75 A 25 25 0 0 1 25 50" 
                  fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="1.5"/>
            <path d="M25 50 A 25 25 0 0 1 50 25 A 25 25 0 0 1 75 50 A 25 25 0 0 1 50 75 A 25 25 0 0 1 25 50" 
                  fill="none" stroke={`${color}`} strokeOpacity={baseOpacity * 0.3} strokeWidth="0.8" transform="rotate(45, 50, 50)"/>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#cage)" />
      </svg>
    ),
    boxes: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="boxes" width="60" height="60" patternUnits="userSpaceOnUse">
            <rect width="30" height="30" x="0" y="0" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="1.5" />
            <rect width="30" height="30" x="30" y="30" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="1.5" />
            <line x1="0" y1="0" x2="30" y2="30" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
            <line x1="30" y1="0" x2="0" y2="30" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
            <line x1="30" y1="30" x2="60" y2="60" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
            <line x1="60" y1="30" x2="30" y2="60" stroke={`${color}`} strokeOpacity={baseOpacity * 0.7} strokeWidth="1" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#boxes)" />
      </svg>
    ),
    chevron: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="chevron" width="60" height="30" patternUnits="userSpaceOnUse">
            <path d="M0 30 L30 0 L60 30" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="2"/>
            <path d="M0 30 L30 60 L60 30" fill="none" stroke={`${color}`} strokeOpacity={baseOpacity} strokeWidth="2"/>
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#chevron)" />
      </svg>
    )
  };

  return (
    <div style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0,
      overflow: "hidden",
    }}>
      {svgPatterns[pattern]}
    </div>
  );
};
function CarouselPage() {
  const { isDark } = useSelector((state) => state.common);
  const [active, setActive] = useState("upload");
  const [titleFont, setTitleFont] = useState("");
  const [bodyFont, setBodyFont] = useState("");
  const [subtitleSize, setSubtitleSize] = useState("");
  const [titleSize, setTitleSize] = useState("");
  const [descSize, setDescSize] = useState("");
  const [textAlignment, setTextAlignment] = useState("");
  const [verticalAlignment, setVerticalAlignment] = useState("");
  const [layout, setLayout] = useState("");
  const [slideNo, setSlideNo] = useState("");
  const [slideStyle, setSlideStyle] = useState("");
  const [roundVal, setRoundVal] = useState("");
  const [isSwipe, setIsSwipe] = useState("");
  const [isBookmark, setIsBookmark] = useState("");
  const [swipeText, setSwipeText] = useState("");
  const [swipeIcon, setSwipeIcon] = useState("");
  const [headShot, setHeadShot] = useState("");
  const [introOutro, setIntroOutro] = useState("");
  const [imgSize, setImgSize] = useState("");
  const [nameSize, setNameSize] = useState("");
  const [handleSize, setHandleSize] = useState("");
  const [headShotColor, setHeadShotColor] = useState("");
  const [pattern, setPattern] = useState("");
  const [bgOpacity, setBgOpacity] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [descColor, setDescColor] = useState("");
  const [subTitleColor, setSubTitleColor] = useState("");
  const [pageNoColor, setPageNoColor] = useState("");
  const [swipeColor, setSwipeColor] = useState("");
  const [bookmarkColor, setBookmarkColor] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [boxColor, setBoxColor] = useState("");
  const [aspectRatio, setAspectRatio] = useState("");
  const [dialogId, setDialogId] = useState("");
  const [dialogId1, setDialogId1] = useState("");
  const [search, setSearch] = useState("");
  const [searchPlat, setSearchPlat] = useState("");
  const [h, setH] = useState("");
  const upper = useSelector((s) => s.adjustReducer);
  const [imgSelect, setImgSelect] = useState("");
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const handlePixabayApi = (e) => {
    if (e) e.preventDefault();
    {
      searchPlat == "Pexels"
        ? dispatch(pexelImageData(search))
        : searchPlat == "Pixabay"
        ? dispatch(pixabayImageData(search))
        : dispatch(unsplashImageData(search));
    }
  };


  const generatePDF = async () => {
    // if (!activeCarousel) {
    //   console.error("No active carousel selected");
    //   return;
    // }
    // setLoading(true);
    dispatch(isLoading(true))

  
    const slides = document.querySelectorAll('.capture-container');
    if (slides.length === 0) return;
  
    // Get dimensions of the first slide to set PDF size
    const firstSlide = slides[0];
    const slideWidth = firstSlide.offsetWidth;
    const slideHeight = firstSlide.offsetHeight;
    const aspectRatio = slideWidth / slideHeight;
  
    // Set PDF size to match slide aspect ratio
    const pdfWidth = 210; // A4 width in mm
    const pdfHeight = pdfWidth / aspectRatio;
    
    const pdf = new jsPDF({
      orientation: pdfHeight > pdfWidth ? 'portrait' : 'landscape',
      unit: 'mm',
      format: [pdfWidth, pdfHeight]
    });
  
    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      
      // Create a canvas from the slide
      const canvas = await html2canvas(slide, {
        scale: 6, // Increase scale for better quality
        useCORS: true,
        logging: false, // Disable logging for better performance
        backgroundColor: null // Transparent background
      });
  
      // Add the slide to the PDF
      if (i > 0) {
        pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? 'portrait' : 'landscape');
      }
      pdf.addImage(
        canvas.toDataURL('image/jpeg', 0.95), // Use JPEG with 95% quality for smaller file size
        'JPEG',
        0, 0, pdfWidth, pdfHeight,
        undefined,
        'FAST' // Use fast compression
      );
    }
    pdf.save("carousel.pdf");
  
    // return pdf;
    dispatch(isLoading(false))
    setShowDialog2(false);
  };

  const handleDeleteRecent = (imageUrl) => {
    dispatch(deleteRecentImage(imageUrl));
  };
  const handleBgAll = (bgImage, bgAllSet, id, opacity) => {
    const data = {
      id: id,
      bgAllSet: bgAllSet,
    };
    console.log(data);
    dispatch(backgroundAllSet(data));
    if (bgAllSet) {
      console.log(bgAllSet, bgImage);
      const data1 = {
        bgImage: bgImage,
        opacity: opacity,
      };
      dispatch(backgroundImageAllSet(data1));
    } else dispatch(revertbackgroundImageSet());
  };

  useEffect(() => {
    dispatch(userProfileData(userData.linkedinId));
  }, []);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [ref, callback]);
  };
  const [openAdjustDropdownIndex, setOpenAdjustDropdownIndex] = useState(null);
  useOutsideClick(dropdownRef, () => {
    setOpenAdjustDropdownIndex(null);
  });

  const [openBackgroundDropdownIndex, setOpenBackgroundDropdownIndex] =
    useState(null);
  useOutsideClick(dropdownRef2, () => {
    setOpenBackgroundDropdownIndex(null);
  });

  const handleToggleAdjustDropdown = (index) => {
    setOpenAdjustDropdownIndex(
      openAdjustDropdownIndex === index ? null : index
    );
  };
  const handleToggleBackgroundDropdown = (index) => {
    setOpenBackgroundDropdownIndex(
      openBackgroundDropdownIndex === index ? null : index
    );
  };
  console.log({ subtitleSize });
  useEffect(() => {
    if (aspectRatio) {
      const [width, height] = aspectRatio.split(":").map(Number);
      setH((520 * height) / width);
    }
  }, [aspectRatio]);

  const handleSubtitleAdjust = (index, setSubtitle) => {
    const data = {
      index: index,
      subtitle: setSubtitle,
    };
    dispatch(adjustSubtitle(data));
  };
  const handleTitleAdjust = (index, setTitle) => {
    const data = {
      index: index,
      title: setTitle,
    };
    dispatch(adjustTitle(data));
  };
  const handleDescAdjust = (index, setDesc) => {
    const data = {
      index: index,
      desc: setDesc,
    };
    dispatch(adjustDesc(data));
  };
  const handleImageAdjust = (index, setImage) => {
    const data = {
      index: index,
      image: setImage,
    };
    dispatch(adjustImage(data));
  };
  const textChange = useSelector((s) => s.textReducer);
  const canvaChange = useSelector((s) => s.canvaReducer);
  const settingsChange = useSelector((s) => s.settingsReducer);
  const headShotChange = useSelector((s) => s.headShotReducer);
  const imageUploadChange = useSelector((s) => s.imageUploadReducer);
  console.log(imageUploadChange.images);

  useEffect(() => {
    setTitleFont(textChange.titleFontType || "Poppins");
    setBodyFont(textChange.bodyFontType || "Poppins");
    setSubtitleSize(textChange.fontSizeSubtitle);
    setTitleSize(textChange.fontSizeTitle);
    setDescSize(textChange.fontSizeDesc);
    setTextAlignment(textChange.textAlign || "center");
    setVerticalAlignment(textChange.verticalAlign || "center");
    setTitleColor(textChange.titleColor);
    setDescColor(textChange.descColor);
    setSubTitleColor(textChange.subTitleColor);
    setPageNoColor(textChange.pageNoColor);
  }, [textChange]);

  useEffect(() => {
    setLayout(canvaChange.layout);
    setPattern(canvaChange.pattern);
    setBgOpacity(canvaChange.bgOpacity);
    setBgColor(canvaChange.bgColor);
    setAspectRatio(canvaChange.aspectRatio);
    setBoxColor(canvaChange.boxDivColor);
  }, [canvaChange]);

  useEffect(() => {
    setSlideNo(settingsChange.slideNo);
    setSlideStyle(settingsChange.slideNoStyle);
    setRoundVal(settingsChange.round);
    setIsSwipe(settingsChange.swipe);
    setIsBookmark(settingsChange.bookmark);
    setSwipeText(settingsChange.swipeText);
    setSwipeIcon(settingsChange.swipeIcon);
    setSwipeColor(settingsChange.swipeColor);
    setBookmarkColor(settingsChange.bookmarkColor);
  }, [settingsChange]);

  useEffect(() => {
    setHeadShot(headShotChange.headShot);
    setIntroOutro(headShotChange.introOutro);
    setImgSize(headShotChange.imgSize);
    setNameSize(headShotChange.nameSize);
    setHandleSize(headShotChange.handleSize);
    setHeadShotColor(headShotChange.headShotColor);
    console.log(headShotChange.headShotColor);
  }, [headShotChange]);

  const handleSubtitleChange = (id, newSubtitle) => {
    const data = {
      id,
      subtitle: newSubtitle,
    };
    dispatch(changeSubtitle(data));
  };

  const handleTitleChange = (id, newTitle) => {
    const data = {
      id,
      title: newTitle,
    };
    dispatch(changeTitle(data));
  };

  const handleDescChange = (id, newDesc) => {
    const data = {
      id,
      desc: newDesc,
    };
    dispatch(changeDesc(data));
  };

  const handleBackgroundImageChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const data = {
          id: id,
          backgroundImage: reader.result,
        };
        dispatch(backgroundImageSet(data));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleBgOpacity = (id, e) => {
    const data = {
      id: id,
      opacity: parseFloat(e.target.value),
    };
    dispatch(backgroundImageOpacitySet(data));
  };

  const handleBgImgSize = (id, size) => {
    const data = {
      id: id,
      size: size,
    };
    dispatch(backgroundImageSizeSet(data));
  };

  const handleImgSize = (id, size) => {
    const data = {
      id: id,
      size: size,
    };
    dispatch(imageSizeSet(data));
    console.log(data);
  };

  const handleRemoveImg = (id) => {
    dispatch(imageSet({ id: id, img: "" }));
  };

  const handleRemoveBgImg = (id) => {
    dispatch(backgroundImageRemove(id));
  };

  const handlebgImgPosition = (id, position) => {
    const data = {
      id: id,
      position: position,
    };
    dispatch(backgroundImagePositionSet(data));
  };
  const handleImgPosition = (id, position) => {
    const data = {
      id: id,
      position: position,
    };
    console.log(data);
    dispatch(imagePositionSet(data));
  };
  const handleAddSlide = (index) => {
    const slide = {
      title: "Title",
      description: "Description",
      id: uuidv4(),
      hasImageUpload: false,
      swipe: false,
      bookmark: false,
      showTitle: true,
      showDesc: true,
      backGroundImg: "",
      prevBg: "",
      bgImgOpacity: 0.2,
      bgImgSize: "cover",
      bgImgPosition: "center",
      img: "",
      imgSize: "cover",
      imgPosition: "center",
      bgAllSet: false,
    };
    const data = {
      index: index,
      slide: slide,
    };
    dispatch(addSlideData(data));
  };
  const image = "/images/pp.jpeg";
  const handleDeleteSlide = (index) => {
    dispatch(deleteSlideData(index));
  };

  const handleDuplicateSlide = (index, slide) => {
    const data = {
      index: index,
      slide: slide,
    };
    dispatch(duplicateSlideData(data));
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(imageUploadAction(reader.result));
      };
      reader.readAsDataURL(file);
    }
    setActive("recents");
  };

  const handlePlatChange = (platform) => {
    setSearchPlat(platform);
  };

  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = () => setShowDialog(false);
  const [showDialog1, setShowDialog1] = useState(false);
  const closeDialog1 = () => {
    setShowDialog1(false);
    setActive("upload");
    setSearch("");
  };
  const [showDialog2, setShowDialog2] = useState(false);
  const closeDialog2 = () => {
    setShowDialog2(false);
    setActive("upload");
    setSearch("");
  };
  const handleDialog = (id) => {
    setShowDialog(true);
    setDialogId(id);
  };
  const handleDialog1 = (id) => {
    setShowDialog1(true);
    setDialogId1(id);
  };
  const handleRecentImage = (id, img) => {
    const data = {
      id: id,
      backgroundImage: img,
    };
    dispatch(backgroundImageSet(data));
    setShowDialog(false);
  };

  const handleImageinImage = (id, img) => {
    const data = {
      id: id,
      img: img,
    };
    dispatch(imageSet(data));
    setShowDialog1(false);
    console.log(data);
  };
  const handleDownloadAllImages = async () => {
    // const zip = new JSZip();
    // const pdf = new JSPdf("landscape");
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = pdf.internal.pageSize.getHeight();

    const length = upper.slides.length;
    let images = [];

    for (let i = 0; i < length; i++) {
      const slideId = `EachSlide${i}`;
      console.log(slideId);
      const captureContainer = document.getElementById(slideId);

      if (captureContainer) {
        let image = await DomToImage.toJpeg(captureContainer, {quality: 1})
          // .then((dataUrl) => {
          //   const link = document.createElement('a');
          //   link.download = `${slideId}.png`;
          //   link.href = dataUrl;
          //   link.click();
          // })
          // .catch((error) => {
          //   console.error('Error exporting to PNG:', error);
          // });
        if(image){
          images.push(image);
        }

        const canvas = await html2canvas(captureContainer, {
          useCORS: true,
          allowTaint: true,
          scale: 2,
        });
        const imgData = canvas.toDataURL('image/png');
        // const canvas = await DomToImage.toPng(captureContainer);
        // const canvas = await DomToImage.toJpeg(captureContainer);
        // window.saveAs(canvas, `${slideId}.png`)
        // console.log(canvas);
        var link = document.createElement('a');
        link.download = `${slideId}.jpeg`;
        link.href = imgData;
        link.click();
        break;

        // pdf.addImage(canvas, "PNG", i * pdfWidth, i * pdfHeight, length * pdfWidth, length * pdfHeight);
        // const imageData = canvas.toDataURL("image/png").split(",")[1];
        // zip.file(`slide_${i}.jpeg`, canvas);
      } else {
        console.error(`Element with id ${slideId} not found.`);
      }
    }

      let pdf = new jsPDF();
      const promises = images.map((url, index) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            const imgWidth = img.width;
            const imgHeight = img.height;
            if(index == 0) {
              pdf = new jsPDF({
                // orientation: 'portrait',
                unit: 'px',
                format: [imgWidth, imgHeight]
              })
            }
            let pdfWidth = pdf.internal.pageSize.getWidth();
            let pdfHeight = pdf.internal.pageSize.getHeight();
            // let pdfHeight = (imgHeight * pdfWidth) / imgWidth;
            if (index > 0) pdf.addPage();
            pdf.addImage(img, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');
            resolve();
          };
          img.onerror = (error) => reject(error);
        });
      });
  
      Promise.all(promises)
        .then(() => {
          pdf.save('images.pdf');
        })
        .catch((error) => {
          console.error('Error exporting to PDF:', error);
        });
  

    // pdf.save("carousal.pdf");

    // zip.generateAsync({ type: "blob" }).then(function (content) {
    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(content);
    //   link.download = "all_slides.zip";
    //   link.click();
    // });
  };


  const ref=useRef(null)

  const  scroll=(scrollOffset)=>{
    ref.current.scrollLeft +=scrollOffset
  }
  
  return (
    <>
      {/* Modal shown on Download onclick */}
      <Modal
        show={showDialog}
        onHide={closeDialog}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="flex gap-6 -mt-7 mb-3 w-full">
            <div
              className={`flex flex-col ${
                isDark ? "text-black" : "text-white"
              } gap-2 -ml-2 w-[18%]`}
            >
              <p
                onClick={() => setActive("upload")}
                className={`cursor-pointer flex gap-2 items-center ${
                  isDark ? "hover:bg-gray-200" : "hover:bg-gray-400"
                } p-2 px-4 rounded-lg ${
                  active == "upload"
                    ? isDark
                      ? "bg-gray-200"
                      : "bg-gray-400"
                    : ""
                }`}
              >
                <img src={isDark ? uploadImg : uploadImgWhite}></img>Upload
                Media
              </p>
              <p
                onClick={() => setActive("recents")}
                className={` cursor-pointer flex gap-2 items-center ${
                  isDark ? "hover:bg-gray-200" : "hover:bg-gray-400"
                } p-2 px-4 rounded-lg ${
                  active == "recents"
                    ? isDark
                      ? "bg-gray-200"
                      : "bg-gray-400"
                    : ""
                }`}
              >
                <img src={isDark ? recentImg : recentImgWhite}></img>Recents
                Upload
              </p>
              <p
                onClick={() => {
                  setActive("search");
                  dispatch(clearImages());
                  setSearch("");
                }}
                className={`cursor-pointer flex gap-2 items-center ${
                  isDark ? "hover:bg-gray-200" : "hover:bg-gray-400"
                } p-2 px-4 rounded-lg ${
                  active == "search"
                    ? isDark
                      ? "bg-gray-200"
                      : "bg-gray-400"
                    : ""
                }`}
              >
                <img src={isDark ? searchImg : searchImgWhite}></img>Search
                Images
              </p>
            </div>
            <div className="text-black w-[79%]">
              {active == "upload" ? (
                <div className="-mr-2">
                  <p
                    className={`font-bold text-lg ${
                      isDark ? "text-black" : "text-white"
                    }`}
                  >
                    Upload Media
                  </p>
                  <label htmlFor="backgroundImg" className="w-full">
                    <div className="border-2 border-gray-200 border-dotted w-full h-[485px] flex flex-col gap-1 justify-center items-center cursor-pointer hover:bg-[#1E1E1E80]">
                      {" "}
                      <img
                        src={addImg}
                        height="16px"
                        className="cursor-pointer"
                      ></img>
                      <input
                        type="file"
                        id="backgroundImg"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => handleUploadImage(e)}
                      />
                      <p className={`${isDark ? "text-black" : "text-white"}`}>
                        Click to select File
                      </p>
                    </div>
                  </label>
                </div>
              ) : null}
              {active == "recents" ? (
                <div className="-mr-2 .hide-scrollBar">
                  <p
                    className={`font-bold text-lg ${
                      isDark ? "text-black" : "text-white"
                    }`}
                  >
                    Recent Uploads
                  </p>
                  <div className="w-full h-[500px] flex gap-1 items-start overflow-y-auto py-1">
                    <div className="flex flex-row flex-wrap gap-4 py-1">
                      {imageUploadChange.recents &&
                      imageUploadChange.recents.length == 0 ? (
                        <p className="mt-2 text-gray-500">
                          No recent uploads yet!
                        </p>
                      ) : null}
                      {imageUploadChange.recents &&
                        [...imageUploadChange.recents]
                          .reverse()
                          .map((s, ind) => (
                            <div key={ind} className="relative">
                              <img
                                src={s}
                                className={`${
                                  imgSelect == s
                                    ? "border-green-900 border-3"
                                    : ""
                                } w-[202px] h-auto object-cover rounded-lg`}
                                onClick={() => setImgSelect(s)}
                              />
                              <i
                                className="fa-regular fa-trash-can media-delete-contain absolute top-2 right-2"
                                style={{ color: "red", cursor: "pointer" }}
                                title="delete"
                                onClick={() => handleDeleteRecent(s)}
                              ></i>
                            </div>
                          ))}
                    </div>
                  </div>
                  {imageUploadChange.recents &&
                  imageUploadChange.recents.length != 0 ? (
                    <button
                      className="float-right text-white px-2 py-[2px] rounded-sm gradient-button-new mt-3"
                      onClick={() => {
                        handleRecentImage(dialogId, imgSelect);
                      }}
                    >
                      Apply
                    </button>
                  ) : null}
                </div>
              ) : null}
              {/* handleRecentImage(dialogId, imageUploadChange.keyWord === 'Pexels' ? i.src.medium :imageUploadChange.keyWord === 'Pexels'? i.webformatURL:i.urls.small ) */}
              {active == "search" ? (
                <div className="-mr-2">
                  <p
                    className={`font-bold text-lg ${
                      isDark ? "text-black" : "text-white"
                    }`}
                  >
                    Search Image
                  </p>
                  <div className="w-full h-[500px] flex flex-col gap-1 justify-start items-start overflow-y-auto py-1">
                    <div
                      className="flex gap-2 flex-wrap w-full py-1 "
                      id="hide-scrollbar"
                    >
                      <form
                        onSubmit={(e) => handlePixabayApi(e)}
                        className="w-full flex justify-between"
                      >
                        <input
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          className="border-[1px] border-gray-200 p-2 rounded-lg w-[74%]"
                          placeholder="Enter terms e.g, happy people"
                          style={{
                            background: "transparent",
                            outline: "none",
                            color: isDark ? "black" : "white",
                          }}
                        ></input>
                        <select
                          className="border-[1px] border-gray-200 p-2 rounded-lg outline-none w-[25%]"
                          onChange={(e) => {
                            setSearchPlat(e.target.value);
                            handlePixabayApi(null);
                          }}
                          style={{ outline: "none" }}
                        >
                          <option value="Pexels">Pexels</option>
                          <option value="Pixabay">Pixabay</option>
                          <option value="Unsplash">Unsplash</option>
                        </select>
                      </form>
                    </div>
                    <div className="flex flex-row flex-wrap gap-4 w-full py-1">
                      {imageUploadChange.images &&
                        imageUploadChange.images.map((i, ind) => (
                          <img
                            src={
                              imageUploadChange.keyWord == "Pexels"
                                ? i.src.medium
                                : imageUploadChange.keyWord == "Pixabay"
                                ? i.webformatURL
                                : i.urls.small
                            }
                            className={`${
                              imgSelect == i ? "border-green-900 border-3" : ""
                            } w-[202px] h-auto object-cover rounded-lg`}
                            key={ind}
                            onClick={() => setImgSelect(i)}
                          ></img>
                        ))}
                    </div>
                  </div>
                  <button
                    className="float-right text-white px-2 py-[2px] rounded-sm gradient-button-new mt-3"
                    onClick={() =>
                      handleRecentImage(
                        dialogId,
                        imageUploadChange.keyWord === "Pexels"
                          ? imgSelect.src.medium
                          : imageUploadChange.keyWord === "Pexels"
                          ? imgSelect.webformatURL
                          : imgSelect.urls.small
                      )
                    }
                  >
                    Apply
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      
      <div
        className="flex flex-col justify-center gap-4 px-[50px] relative"
        id="CarouselDiv"
      > 
        <div className="flex">
          {upper.slides &&
            upper.slides.map((slide, index) => (
              <div
                className={`${
                  aspectRatio === "1:1"
                    ? "-top-10"
                    : aspectRatio === "4:5"
                    ? "-top-[46px]"
                    : aspectRatio === "3:4"
                    ? "-top-5"
                    : aspectRatio === "4:3"
                    ? "-top-12"
                    : "-top-12"
                } relative overscroll-y-none`}
                key={index}
              >
                <div className="capture-container">
                  <div
                    className={`relative w-[520px] gap-3 px-[20px] flex flex-col  ${
                      verticalAlignment == "center"
                        ? "justify-center"
                        : verticalAlignment == "start"
                        ? "justify-start"
                        : "justify-end"
                    } items-center py-[100px] ${
                      textAlignment == "center"
                        ? "text-center"
                        : textAlignment == "left"
                        ? "text-left"
                        : "text-right"
                    } `}
                    style={{
                      minHeight: h || "520px",
                      backgroundImage: slide.backGroundImg
                        ? `url(${slide.backGroundImg})`
                        : "none",
                      backgroundSize: slide.bgImgSize || "cover",
                      backgroundPosition: slide.bgImgPosition,
                      backgroundRepeat: "no-repeat",
                      backgroundBlendMode: slide.backGroundImg
                        ? "overlay"
                        : "normal",
                      backgroundColor: slide.backGroundImg
                        ? `rgba(255, 255, 255, ${1 - slide.bgImgOpacity})`
                        : bgColor,
                    }}
                  >
                    <PatternContainer pattern={pattern} bgOpacity={bgOpacity} />
                    <div className="flex justify-between items-center w-[90%] z-10 absolute -top-10">
                      <div className="flex gap-4">
                        <div>
                          <img
                            src={isDark ? adjust : adjustWhite}
                            key={index}
                            className="cursor-pointer mt-2 tooltipClass"
                            title="Adjust"
                            onClick={() => handleToggleAdjustDropdown(index)}
                          ></img>
                          {openAdjustDropdownIndex === index && (
                            <div
                              className={`absolute ${
                                isDark ? "bg-white" : "bg-[#1E1E1E]"
                              } top-11 w-[200px] -left-6 p-2 rounded-lg
                flex flex-col gap-2`}
                              ref={dropdownRef}
                            >
                              {slide.subtitle ? (
                                <div
                                  className="flex justify-between items-center"
                                  id="SubtitleSlider"
                                >
                                  <p>Subtitle</p>
                                  <Switch
                                    checked={slide.showSubtitle}
                                    onCheckedChange={() =>
                                      handleSubtitleAdjust(
                                        index,
                                        !slide.showSubtitle
                                      )
                                    }
                                    className="bg-blue-500 data-[state=checked]:bg-blue-500"
                                  />
                                </div>
                              ) : null}
                              <div className="flex justify-between items-center">
                                <p>Title</p>
                                <Switch
                                  checked={slide.showTitle}
                                  onCheckedChange={() =>
                                    handleTitleAdjust(index, !slide.showTitle)
                                  }
                                  className="bg-blue-500 data-[state=checked]:bg-blue-500"
                                />
                              </div>
                              <div
                                className="flex justify-between items-center"
                                id="DescSlider"
                              >
                                <p>Description</p>
                                <Switch
                                  checked={slide.showDesc}
                                  onCheckedChange={() =>
                                    handleDescAdjust(index, !slide.showDesc)
                                  }
                                  className="bg-blue-500 data-[state=checked]:bg-blue-500"
                                />
                              </div>
                              <div className="flex justify-between items-center">
                                <p>Image</p>
                                <Switch
                                  checked={slide.hasImageUpload}
                                  onCheckedChange={() => {
                                    handleImageAdjust(
                                      index,
                                      !slide.hasImageUpload
                                    );
                                    handleImageSliderChange();
                                  }}
                                  className="bg-blue-500 data-[state=checked]:bg-blue-500"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          {slide.backGroundImg ? (
                            <>
                              <img
                                src={isDark ? imgSetting : imgSettingWhite}
                                className="mt-2 cursor-pointer tooltipClass"
                                key={index}
                                title="Image Setting"
                                onClick={() =>
                                  handleToggleBackgroundDropdown(index)
                                }
                              ></img>{" "}
                            </>
                          ) : null}
                          {openBackgroundDropdownIndex === index && (
                            <div
                              className={`absolute bg-white top-8 w-[200px] left-0 p-2 rounded-lg shadow-md shadow-gray-300 ${"flex flex-col gap-1"}`}
                              ref={dropdownRef2}
                            >
                              <div>
                                <img
                                  src={slide.backGroundImg}
                                  className="w-[100%]"
                                ></img>
                              </div>
                              <div className="flex items-center justify-between">
                                <label htmlFor={`backgroundImg${index}`}>
                                  <p
                                    className="cursor-pointer border-gray-200 border-2 rounded-lg w-[100%] p-2 px-3 text-center"
                                    onClick={() => handleDialog(slide.id)}
                                    style={{
                                      color: isDark ? "black" : "black",
                                    }}
                                  >
                                    Choose Image
                                  </p>
                                </label>

                                <label
                                  className="switch tooltipClass"
                                  title="Set Background Image to all "
                                >
                                  <input
                                    type="checkbox"
                                    checked={slide.bgAllSet}
                                    onChange={() =>
                                      handleBgAll(
                                        slide.backGroundImg,
                                        !slide.bgAllSet,
                                        slide.id,
                                        slide.bgImgOpacity
                                      )
                                    }
                                  />
                                  <span className="slider round "></span>
                                </label>
                              </div>
                              <div className="flex flex-col items-start -mt-3">
                                <label>Image Opacity</label>
                                <input
                                  type="range"
                                  min="0"
                                  max="1"
                                  step="0.01"
                                  value={slide.bgImgOpacity}
                                  onChange={(e) => handleBgOpacity(slide.id, e)}
                                  className="w-full"
                                />
                              </div>
                              <div className="flex justify-between">
                                {slide.bgImgSize == "cover" ? (
                                  <img
                                    src={minimize}
                                    className="cursor-pointer tooltipClass"
                                    title="Minimize"
                                    onClick={() =>
                                      handleBgImgSize(slide.id, "contain")
                                    }
                                  ></img>
                                ) : (
                                  <img
                                    src={maximize}
                                    className="cursor-pointer tooltipClass"
                                    onClick={() =>
                                      handleBgImgSize(slide.id, "cover")
                                    }
                                    title="Maximize"
                                  ></img>
                                )}
                                <img
                                  src={leftAlign}
                                  className="cursor-pointer tooltipClass"
                                  key={index}
                                  title="Left Align"
                                  onClick={() =>
                                    handlebgImgPosition(slide.id, "left")
                                  }
                                ></img>
                                <img
                                  src={centerAlign}
                                  className="cursor-pointer tooltipClass"
                                  key={index}
                                  title="Center Align"
                                  onClick={() =>
                                    handlebgImgPosition(slide.id, "center")
                                  }
                                ></img>
                                <img
                                  src={rightAlign}
                                  className="cursor-pointer tooltipClass"
                                  key={index}
                                  title="Right Align"
                                  onClick={() =>
                                    handlebgImgPosition(slide.id, "right")
                                  }
                                ></img>
                                <img
                                  src={deleteSlide}
                                  className="cursor-pointer tootipClass"
                                  key={index}
                                  title="Delete Image"
                                  onClick={() => {
                                    handleRemoveBgImg(slide.id);
                                    handleToggleBackgroundDropdown(index);
                                  }}
                                ></img>
                              </div>
                            </div>
                          )}

                          {slide.backGroundImg ? null : (
                            <>
                              <label htmlFor={`backgroundImg${index}`}>
                                {" "}
                                <img
                                  src={isDark ? addImg : addImgWhite}
                                  height="16px"
                                  className="cursor-pointer tooltipClass"
                                  key={index}
                                  title="Background Image"
                                  onClick={() => handleDialog(slide.id)}
                                ></img>
                              </label>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <button id="duplicateSlideImg">
                          {index == 0 || index == upper.slides.length - 1 ? (
                            <img
                              src={
                                isDark ? disabledDuplicate : duplicateSlideImg
                              }
                              alt="Disabled Duplicate Slide"
                              className="cursor-not-allowed"
                            />
                          ) : (
                            <img
                              src={
                                isDark ? duplicateSlideImg : disabledDuplicate
                              }
                              alt="Enabled Duplicate Slide"
                              className="cursor-pointer tooltipClass"
                              title="Duplicate Slide"
                              onClick={() => handleDuplicateSlide(index, slide)}
                            />
                          )}
                        </button>
                        <button id="deleteSlideImg">
                          {index == 0 || index == upper.slides.length - 1 ? (
                            <img
                              src={isDark ? disabledDelete : deleteSlide}
                              className="cursor-not-allowed"
                            />
                          ) : (
                            <img
                              src={isDark ? deleteSlide : disabledDelete}
                              className="cursor-pointer tooltipClass"
                              title="Delete Slide"
                              onClick={() => handleDeleteSlide(slide.id)}
                            />
                          )}
                        </button>
                        <button id="addSlideImg">
                          {index == upper.slides.length - 1 ? (
                            <img
                              src={isDark ? disabledAdd : addSlide}
                              className="cursor-not-allowed"
                            />
                          ) : (
                            <img
                              src={isDark ? addSlide : disabledAdd}
                              className="cursor-pointer tooltipClass"
                              title="Add Slide"
                              onClick={() => handleAddSlide(index)}
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`flex flex-col p-3 absolute w-[95%] rounded-lg top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 ${
                        verticalAlignment == "center"
                          ? "justify-center"
                          : verticalAlignment == "start"
                          ? "justify-start"
                          : "justify-end"
                      }`}
                      style={{
                        backgroundColor: layout == "boxed" ? boxColor : "",
                        height:
                          aspectRatio == "4:3"
                            ? "68%"
                            : aspectRatio == "16:9"
                            ? "62%"
                            : "75%",
                      }}
                    >
                      <div
                        className={` w-full flex  ${
                          textAlignment == "center"
                            ? "justify-center"
                            : textAlignment == "left"
                            ? "justify-start"
                            : "justify-end"
                        }`}
                      >
                        {index > 0 &&
                        index != upper.slides.length - 1 &&
                        slideNo ? (
                          <div
                            className={`${
                              slideStyle == "round"
                                ? " text-white font-medium text-lg"
                                : "font-medium text-lg"
                            } flex items-center justify-center rounded-full `}
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: `${roundVal * 50}%`,
                              zIndex: 1,
                              marginBottom: "1px",
                              backgroundColor:
                                slideStyle === "round"
                                  ? pageNoColor
                                  : "transparent",
                              color:
                                slideStyle !== "round" ? pageNoColor : "white",
                            }}
                          >
                            {slideStyle == "round" ? index : "0" + index}
                          </div>
                        ) : null}
                      </div>
                      <div className="" style={{ zIndex: 9 }}>
                        {slide.showSubtitle && slide.subtitle && (
                          <p
                            contentEditable="true"
                            className={`outline-none font-medium w-[460px] text-${subtitleSize}`}
                            style={{
                              fontFamily: bodyFont,
                              color: subTitleColor,
                              zIndex: 1,
                            }}
                            onBlur={(e) =>
                              handleSubtitleChange(slide.id, e.target.innerText)
                            }
                          >
                            {slide.subtitle}
                          </p>
                        )}
                        {slide.showTitle && slide.title && (
                          <p
                            contentEditable="true"
                            className={`outline-none py-2 font-bold w-[460px] ${
                              titleSize == "2xl"
                                ? "text-2xl"
                                : titleSize == "3xl"
                                ? "text-3xl"
                                : "text-xl"
                            }`}
                            style={{
                              fontFamily: titleFont,
                              zIndex: 1,
                              color: titleColor,
                            }}
                            onBlur={(e) =>
                              handleTitleChange(slide.id, e.target.innerText)
                            }
                          >
                            {slide.title}
                          </p>
                        )}
                        {slide.showDesc && slide.description && (
                          <p
                            contentEditable="true"
                            className={`outline-none font-medium w-[460px] ${
                              descSize == "base"
                                ? "text-base"
                                : descSize == "lg"
                                ? "text-lg"
                                : "text-xl"
                            }`}
                            style={{
                              fontFamily: bodyFont,
                              zIndex: 1,
                              color: descColor,
                            }}
                            onBlur={(e) =>
                              handleDescChange(slide.id, e.target.innerText)
                            }
                          >
                            {slide.description}
                          </p>
                        )}
                      </div>
                      <div className={` w-full flex justify-center`}>
                        {slide.hasImageUpload && (
                          <>
                            <label
                              htmlFor={`img${index}`}
                              className="w-full cursor-pointer"
                            >
                              {slide.img ? (
                                <div
                                  className="flex justify-center items-center image-container h-[230px] rounded-xl object-cover"
                                  style={{
                                    height:
                                      aspectRatio == "1:1"
                                        ? "13rem"
                                        : aspectRatio == "4:5"
                                        ? "18rem"
                                        : aspectRatio == "3:4"
                                        ? "20rem"
                                        : aspectRatio == "16:9"
                                        ? "5rem"
                                        : "7rem",
                                    width: "100%",
                                    backgroundImage: slide.img
                                      ? `url(${slide.img})`
                                      : "none",
                                    backgroundSize: slide.imgSize || "cover",
                                    backgroundPosition:
                                      slide.imgPosition || "center",
                                    backgroundRepeat: "no-repeat",
                                    zIndex: 10,
                                  }}
                                >
                                  {/* <img
                                src={slide.img}
                                alt="Uploaded"
                                className="rounded-xl"
                              /> */}
                                  <div className="overlayDiv h-[230px] w-full rounded-xl">
                                    <div className="flex gap-3">
                                      <img
                                        src={addImgWhite}
                                        onClick={() => handleDialog1(slide.id)}
                                      ></img>
                                      {slide.imgSize == "cover" ? (
                                        <img
                                          src={minimizeWhite}
                                          className="tooltipClass"
                                          title="Minimize"
                                          onClick={() =>
                                            handleImgSize(slide.id, "contain")
                                          }
                                        ></img>
                                      ) : (
                                        <img
                                          src={maximizeWhite}
                                          className="tooltipClass"
                                          title="Maximize"
                                          onClick={() =>
                                            handleImgSize(slide.id, "cover")
                                          }
                                        ></img>
                                      )}
                                    </div>
                                    <div className="flex gap-3">
                                      <img
                                        src={leftAlignWhite}
                                        className="tooltipClass"
                                        title="Left Align"
                                        onClick={() =>
                                          handleImgPosition(slide.id, "left")
                                        }
                                      ></img>
                                      <img
                                        src={centerAlignWhite}
                                        className="tooltipClass"
                                        title="Center Align"
                                        onClick={() =>
                                          handleImgPosition(slide.id, "center")
                                        }
                                      ></img>
                                      <img
                                        src={rightAlignWhite}
                                        className="tooltipClass"
                                        title="Right Align"
                                        onClick={() =>
                                          handleImgPosition(slide.id, "right")
                                        }
                                      ></img>
                                      <img
                                       src={deleteSlide}
                                       className="tooltipClass"
                                       onClick={()=>handleRemoveImg(slide.id)} >
                                        
                                      </img>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex justify-center items-center text-center">
                                  <div
                                    className="w-full border border-gray-500 rounded-xl flex justify-center items-center"
                                    style={{
                                      zIndex: 1000,
                                      height:
                                        aspectRatio == "1:1"
                                          ? "13rem"
                                          : aspectRatio == "4:5"
                                          ? "19rem"
                                          : aspectRatio == "3:4"
                                          ? "21rem"
                                          : aspectRatio == "16:9"
                                          ? "2rem"
                                          : "7rem",
                                    }}
                                  >
                                    <img
                                      src={addImg}
                                      alt="Add Image"
                                      style={{ zIndex: 1 }}
                                      onClick={() => handleDialog1(slide.id)}
                                    />
                                  </div>
                                </div>
                              )}
                            </label>
                            <Modal
                              show={showDialog1}
                              onHide={closeDialog1}
                              size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              className={isDark ? "" : "isdark-model"}
                              centered
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <div className="flex gap-6 -mt-7 mb-3 w-full">
                                  <div
                                    className={`flex flex-col ${
                                      isDark ? "text-black" : "text-white"
                                    } gap-2 -ml-2 w-[18%]`}
                                  >
                                    <p
                                      onClick={() => setActive("upload")}
                                      className={`cursor-pointer flex gap-2 items-center ${
                                        isDark
                                          ? "hover:bg-gray-200"
                                          : "hover:bg-gray-400"
                                      } p-2 px-4 rounded-lg ${
                                        active == "upload"
                                          ? isDark
                                            ? "bg-gray-200"
                                            : "bg-gray-400"
                                          : ""
                                      }`}
                                    >
                                      <img
                                        src={
                                          isDark ? uploadImg : uploadImgWhite
                                        }
                                      ></img>
                                      Upload Media
                                    </p>
                                    <p
                                      onClick={() => setActive("recents")}
                                      className={` cursor-pointer flex gap-2 items-center ${
                                        isDark
                                          ? "hover:bg-gray-200"
                                          : "hover:bg-gray-400"
                                      } p-2 px-4 rounded-lg ${
                                        active == "recents"
                                          ? isDark
                                            ? "bg-gray-200"
                                            : "bg-gray-400"
                                          : ""
                                      }`}
                                    >
                                      <img
                                        src={
                                          isDark ? recentImg : recentImgWhite
                                        }
                                      ></img>
                                      Recents Upload
                                    </p>
                                    <p
                                      onClick={() => {
                                        setActive("search");
                                        dispatch(clearImages());
                                        setSearch("");
                                      }}
                                      className={`cursor-pointer flex gap-2 items-center ${
                                        isDark
                                          ? "hover:bg-gray-200"
                                          : "hover:bg-gray-400"
                                      } p-2 px-4 rounded-lg ${
                                        active == "search"
                                          ? isDark
                                            ? "bg-gray-200"
                                            : "bg-gray-400"
                                          : ""
                                      }`}
                                    >
                                      <img
                                        src={
                                          isDark ? searchImg : searchImgWhite
                                        }
                                      ></img>
                                      Search Images
                                    </p>
                                  </div>
                                  <div className="text-black w-[79%]">
                                    {active == "upload" ? (
                                      <div className="-mr-2">
                                        <p
                                          className={`font-bold text-lg ${
                                            isDark ? "text-black" : "text-white"
                                          }`}
                                        >
                                          Upload Media
                                        </p>
                                        <label
                                          htmlFor="backgroundImg"
                                          className="w-full"
                                        >
                                          <div className="border-2 border-gray-200 border-dotted w-full h-[485px] flex flex-col gap-1 justify-center items-center cursor-pointer hover:bg-[#1E1E1E80]">
                                            {" "}
                                            <img
                                              src={addImg}
                                              height="16px"
                                              className="cursor-pointer"
                                            ></img>
                                            <input
                                              type="file"
                                              id="backgroundImg"
                                              accept="image/*"
                                              className="hidden"
                                              onChange={(e) =>
                                                handleUploadImage(e)
                                              }
                                            />
                                            <p
                                              className={`${
                                                isDark
                                                  ? "text-black"
                                                  : "text-white"
                                              }`}
                                            >
                                              Click to select File
                                            </p>
                                          </div>
                                        </label>
                                      </div>
                                    ) : null}
                                    {active == "recents" ? (
                                      <div className="-mr-2 .hide-scrollBar">
                                        <p
                                          className={`font-bold text-lg ${
                                            isDark ? "text-black" : "text-white"
                                          }`}
                                        >
                                          Recent Uploads
                                        </p>
                                        <div className="w-full h-[500px] flex gap-1 items-start overflow-y-auto py-1">
                                          <div className="flex flex-row flex-wrap gap-4 py-1">
                                            {imageUploadChange.recents &&
                                            imageUploadChange.recents.length ==
                                              0 ? (
                                              <p className="mt-2 text-gray-500">
                                                No recent uploads yet!
                                              </p>
                                            ) : null}
                                            {imageUploadChange.recents &&
                                              [...imageUploadChange.recents]
                                                .reverse()
                                                .map((s, ind) => (
                                                  <div
                                                    key={ind}
                                                    className="relative"
                                                  >
                                                    <img
                                                      src={s}
                                                      className={`${
                                                        imgSelect == s
                                                          ? "border-green-900 border-3"
                                                          : ""
                                                      } w-[202px] h-auto object-cover rounded-lg`}
                                                      onClick={() =>
                                                        setImgSelect(s)
                                                      }
                                                    />
                                                    <i
                                                      className="fa-regular fa-trash-can media-delete-contain absolute top-2 right-2"
                                                      style={{
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                      title="delete"
                                                      onClick={() =>
                                                        handleDeleteRecent(s)
                                                      }
                                                    ></i>
                                                  </div>
                                                ))}
                                          </div>
                                        </div>
                                        <button
                                          className="float-right text-white px-2 py-[2px] rounded-sm gradient-button-new mt-3
          "
                                          onClick={() => {
                                            handleImageinImage(
                                              dialogId1,
                                              imgSelect
                                            );
                                          }}
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    ) : null}
                                    {active == "search" ? (
                                      <div className="-mr-2">
                                        <p
                                          className={`font-bold text-lg ${
                                            isDark ? "text-black" : "text-white"
                                          }`}
                                        >
                                          Search Image
                                        </p>
                                        <div className="w-full h-[500px] flex flex-col gap-1 justify-start items-start overflow-y-auto py-1">
                                          <div
                                            className="flex gap-2 flex-wrap w-full py-1 "
                                            id="hide-scrollbar"
                                          >
                                            <form
                                              onSubmit={(e) =>
                                                handlePixabayApi(e)
                                              }
                                              className="w-full flex justify-between"
                                            >
                                              <input
                                                type="text"
                                                value={search}
                                                onChange={(e) =>
                                                  setSearch(e.target.value)
                                                }
                                                className="border-[1px] border-gray-200 p-2 rounded-lg w-[74%]"
                                                placeholder="Enter terms e.g, happy people"
                                                style={{
                                                  background: "transparent",
                                                  outline: "none",
                                                  color: isDark
                                                    ? "black"
                                                    : "white",
                                                }}
                                              ></input>
                                              <select
                                                className="border-[1px] border-gray-200 p-2 rounded-lg outline-none w-[25%]"
                                                onChange={(e) => {
                                                  setSearchPlat(e.target.value);
                                                  handlePixabayApi(null);
                                                }}
                                                style={{ outline: "none" }}
                                              >
                                                <option value="Pexels">
                                                  Pexels
                                                </option>
                                                <option value="Pixabay">
                                                  Pixabay
                                                </option>
                                                <option value="Unsplash">
                                                  Unsplash
                                                </option>
                                              </select>
                                            </form>
                                          </div>
                                          <div className="flex flex-row flex-wrap gap-4 w-full py-1">
                                            {imageUploadChange.images &&
                                              imageUploadChange.images.map(
                                                (i, ind) => (
                                                  <img
                                                    src={
                                                      imageUploadChange.keyWord ==
                                                      "Pexels"
                                                        ? i.src.medium
                                                        : imageUploadChange.keyWord ==
                                                          "Pixabay"
                                                        ? i.webformatURL
                                                        : i.urls.small
                                                    }
                                                    className={`${
                                                      imgSelect == i
                                                        ? "border-green-900 border-3"
                                                        : ""
                                                    } w-[202px] h-auto object-cover rounded-lg`}
                                                    key={ind}
                                                    onClick={() =>
                                                      setImgSelect(i)
                                                    }
                                                  ></img>
                                                )
                                              )}
                                          </div>
                                        </div>
                                        <button
                                          className="float-right text-white px-2 py-[2px] rounded-sm gradient-button-new mt-3"
                                          onClick={() =>
                                            handleImageinImage(
                                              dialogId1,
                                              imageUploadChange.keyWord ===
                                                "Pexels"
                                                ? imgSelect.src.medium
                                                : imageUploadChange.keyWord ===
                                                  "Pexels"
                                                ? imgSelect.webformatURL
                                                : imgSelect.urls.small
                                            )
                                          }
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-4 left-4 flex justify-between items-center w-full">
                    <div>
                      {headShot && !introOutro ? (
                        <div className="flex flex-row gap-2 items-center">
                          {userData && (
                            <img
                              src={
                                userData.linkedinProfile
                                  ? userData.linkedinProfile
                                  : image
                              }
                              width={imgSize || "36px"}
                              height={imgSize || "36px"}
                              style={{ borderRadius: "50%" }}
                            ></img>
                          )}
                          <div className="flex flex-col">
                            {userData && (
                              <p
                                className={`text-${nameSize} `}
                                style={{ color: headShotColor }}
                              >
                                {userData.userName}
                              </p>
                            )}
                            {userData && (
                              <p
                                className={`text-${handleSize}`}
                                style={{ color: headShotColor }}
                              >
                                @{userData.userName}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        introOutro &&
                        (index == 0 || index == upper.slides.length - 1) && (
                          <div className="flex flex-row gap-2 items-center">
                            {userData && (
                              <img
                                src={
                                  userData.linkedinProfile
                                    ? userData.linkedinProfile
                                    : image
                                }
                                width={imgSize || "36px"}
                                style={{ borderRadius: "50%" }}
                              ></img>
                            )}
                            <div>
                              {userData && (
                                <p
                                  className={`text-${nameSize} `}
                                  style={{ color: headShotColor }}
                                >
                                  {userData.userName}
                                </p>
                              )}
                              {userData && (
                                <p
                                  className={`text-${handleSize}`}
                                  style={{ color: headShotColor }}
                                >
                                  @{userData.userName}
                                </p>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="absolute bottom-2 right-8 flex items-center">
                      {isSwipe && !(index == upper.slides.length-1) && (
                        <div
                          className={`${
                            swipeText
                              ? "px-3 py-[2px] flex gap-1 items-center "
                              : "p-1 flex items-center "
                          }`}
                          style={{
                            borderRadius: `${
                              swipeText ? "5%" : `${roundVal * 50}%`
                            }`,
                            backgroundColor: swipeColor,
                            zIndex: 10000,
                          }}
                        >
                          {swipeText ? (
                            <p className="text-white">{swipeText}</p>
                          ) : null}
                          {swipeIcon == "rightArr" ? (
                            <img src={rightArr}></img>
                          ) : null}
                          {swipeIcon == "swipeArr" ? (
                            <img src={swipeArr}></img>
                          ) : null}
                          {swipeIcon == "circle" ? (
                            <img src={circle}></img>
                          ) : null}
                          {swipeIcon == "thunder" ? (
                            <img src={thunder}></img>
                          ) : null}
                          {swipeIcon == "cross" ? (
                            <img className="invisible" src={thunder}></img>
                          ) : null}
                        </div>
                      )}
                      {console.log(
                        "slide.bookmark:",
                        slide.bookmark,
                        "isBookmark:",
                        isBookmark
                      )}
                      {isBookmark && index == upper.slides.length - 1 && (
                        <div
                          className="p-1 "
                          style={{
                            borderRadius: `${roundVal * 50}%`,
                            backgroundColor: bookmarkColor,
                          }}
                        >
                          <img src={bookmarkImg} width="20px" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            
        </div>
      </div>
    </>
  );
}


export default CarouselPage;
