import React from 'react'
import PostCard from '../PostCard/PostCard';
import './DailyView.css';
import { useSelector } from 'react-redux';





const DailyView = (props) => {
  const { layoutShift } = useSelector((state) => state.common);
  const getScheduleData = props.scheduledData[0]?.filter((item) => item?.date_time?.substring(0, 10) === props.selectedDate)

  return (
    <div
      className={`flex flex-col gap-4 py-12 ${
        layoutShift ? "pl-24 pr-20" : "px-9 sm:px-20"
      }`}
    >
      <div className="flex flex-col gap-4">
        {getScheduleData?.length === 0 ? (
          <div className="w-full text-center">
            <h3 className="text-xl">No post found!</h3>
          </div>
        ) : (
          <div className="masonry-grid w-full -ml-2">
            {getScheduleData?.map((item, i) => (
              <div className="masonry-grid-item" key={i}>
                <PostCard 
                  des={item.postContent} 
                  image={item.originalUrl} 
                  postype={item.postType || 'NONE'} 
                  data={item} 
                  isEvergreen={false} 
                  isBookmark={false}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default DailyView